.icon_pack_wrapper{
display: flex;

flex-wrap: wrap;
align-items: center;
justify-content: center;
width: 80vw;

}
.icon_pack_main_wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1c2237;

}
.icon_set1_1{
width: 20vw;
height: 30vh;
padding: 2rem;
margin: 1rem;
display: flex;
flex-direction: column;
align-items: center;
background-color: whitesmoke;
justify-content: center;
border-radius: 1rem;
border-top: 5px solid #05C7F2;
}

.icon_top{
    font-size: 4rem;
    color: #0597F2;
}

.icon_title{
    color: #1c2237;
    font-size: 2rem;
    text-align: center;

}

@media screen and (max-width:968px) {
    .icon_set1_1{
        width: 70vw;
        height: 30vh;
        padding: 2rem;
        margin: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: whitesmoke;
        justify-content: center;
        border-radius: 1rem;
        border-top: 5px solid #0597F2;
        }
        
        .icon_top{
            font-size: 4rem;
            color: #0597F2;
        }
        
        .icon_title{
            color: #1c2237;
            font-size: 2.2rem;
            text-align: center;
        
        }

}