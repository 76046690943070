.hero-section {
    background: url('/public//images/backdropMain.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    vertical-align: middle;
    align-items: start;
    height: 70vh;
  }

  .text_wrapper{
    background-color: RGBA(111,4,217,0.2);
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
    height: 70vh;
    vertical-align: middle;
    display: flex;
    flex-direction: column;
    justify-content: center;
    vertical-align: middle;
    align-items: start;
  }

  .icon_section{
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    
  }

  .text-area{
    display: flex;
    flex-direction: column;
    width: 50vw;
   
    max-width: 50vw;
    margin-left: 3rem;
    align-items: start;
  }
  
  .company-name {
    font-size: 3.5rem;
    margin-bottom: 1rem;
    color: #1B0273;
    letter-spacing: 5px;
    margin-top: 2rem;
  }
  
  .slogan {
    font-size: 2rem;
    font-weight: 400;
    word-wrap: break-word;
    max-width: 40vw;
    color: white;
    letter-spacing: 3px;

  }
  
  @media (max-width: 768px) {
    .company-name {
      font-size: 2rem;
    }
  
    .slogan {
      font-size: 1rem;
    }

    .text_wrapper{
        background-color: RGBA(111,4,217,0.2);
        border-top-right-radius: 1rem;
        border-bottom-right-radius: 1rem;
        height: 70vh;
        vertical-align: middle;
        display: flex;
        flex-direction: column;
        justify-content: center;
        vertical-align: middle;
        align-items: start;
      }
    
      .text-area{
        display: flex;
        flex-direction: column;
        width: 50vw;
        margin-left: 0rem;
       
        max-width: 50vw;
        margin-left: 1rem;
        align-items: start;
      }


  }
  