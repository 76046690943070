


.large_text {
  font-size: 1rem;
  text-decoration: underline;
  margin-top: 1rem;
  color: whitesmoke;
}

.small_text {
  font-size: 2rem;
  padding: 1rem;
  color: whitesmoke;
}

.contact_wrapper {
  background-color: #1c2237;
  margin-top: 1rem;
  padding: 1rem;
  border-radius: 1rem;
  border-top: 5px solid #05C7F2;
}

@media screen and (max-width: 991px) {
  .container {
    padding-right: 30px;
    padding-left: 30px;
  }
}

@media screen and (max-width: 768px) {


  .large_text {
    font-size: 1rem;
    text-decoration: underline;
    margin-top: 1rem;
    color: whitesmoke;
  }
  
  .small_text {
    font-size: 1.5rem;
    padding: 1rem;
    color: whitesmoke;
  }
}